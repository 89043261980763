import { useState, useMemo } from "react";
import MaterialReactTable from "material-react-table";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import { DateTime, Interval } from "luxon";

import { Button, Typography } from "@material-ui/core";
import { Box } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
// import { Delete, Cell } from "@mui/icons-material";
// import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import BandsSelect from "../../bands/BandsSelect.js";
import VenuesSelect from "../../venues/VenuesSelect.js";
import AddEntry from "./AddEntry";
import {venueColumns} from '../../../constants/table';
import AddItem from '../../../components/AddItem';

import "../styles.scss";

const ReviewEntries = ({events, venues, bands, unresolved, show, interval, onEventChange, onDateChange, onShowChange, onAddVenue, onAddBand, onDeleteEvent, onCreateEvent, onPublish}) => {
	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [createVenuModalOpen, setCreateVenuModalOpen] = useState(false);
	const { width, height } = useWindowSize();
	
	const hasUnresolved = unresolved?.length > 0;
	const data = (hasUnresolved && show !== 'resolved' ? unresolved: events);

	const columns = useMemo(
    () => {
		if (data?.length > 0 && show) {
			return [
				{
					id: "startDate",
					accessorFn: (row) => row.startTime.toLocaleString(DateTime.DATE_SIMPLE),
					header: "Date",
					size: 1,
					editVariant: "select",
					enableEditing: true,
					Cell: ({ cell, column, table }) => (
						<DatePicker
							value={data[cell.row.index].startTime}
							onChange={(value) => onEventChange(value, cell)}
						/>
					),
					type: "date",
				},
				{
					id: "startTime",
					accessorFn: (row) => row.startTime.toLocaleString(DateTime.TIME_SIMPLE),
					header: "Start",
					size: 1,
					Cell: ({ cell, column, table }) => (
						<TimePicker
							value={data[cell.row.index][cell.column.id]}
							onChange={(value) => onEventChange(value, cell)}
						/>
					),
					type: "time",
				},
				{
					id: "endTime",
					accessorFn: (row) => row.endTime.toLocaleString(DateTime.TIME_SIMPLE),
					header: "End",
					size: 1,
					Cell: ({ cell, column, table }) => (
						<TimePicker
							value={data[cell.row.index][cell.column.id]}
							onChange={(value) => onEventChange(value, cell)}
						/>
					),
					type: "time",
				},
				{
					id: "band",
					accessorFn: (row) => row.band?.Name || "Unmatched",
					header: "Band",
					Cell: ({ cell, column, table }) => (
						<BandsSelect
							bands={bands}
							selected={cell.renderValue()}
							onChange={(value) => onEventChange(value, cell)}
							onAddBand={onAddBand}
						/>
					),
					type: "band",
				},
				{
					id: "venue",
					accessorFn: (row) => row.venue?.Venue || "Unmatched",
					header: "Venue",
					Cell: ({ cell, column, table }) => (
						<VenuesSelect
							venues={venues}
							selected={cell.renderValue()}
							onChange={(value) => onEventChange(value, cell)}
							onAddVenue={onAddVenue}
						/>
					),
					type: "venue",
				},
		]}

		return []
	},[bands, data, onAddBand, onAddVenue, onEventChange, show, venues]);

	const dateChangeHandler = (date, dateToChange) => {	

		const newInterval = (dateToChange === 'start') ? Interval.fromDateTimes(date, interval.end) : Interval.fromDateTimes(interval.start, date);
		onDateChange(newInterval);
	};

	return (
		<div className="page">
			{data &&
				<>
				<Confetti
				width={width}
				height={height}
				recycle={false}
				run={!hasUnresolved}
				/>
				
				<div className="header">
					<div className="headerLeft">
						<Typography variant="h5">
							{`Showing events between`}
							<DatePicker defaultValue={interval.start} sx={{mx:1, width: 135}} slotProps={{ textField: { variant: 'standard', } }} onChange={(date) => dateChangeHandler(date, "start")}  minDate={DateTime.now().startOf('week')}/> -
							<DatePicker defaultValue={interval.end} sx={{mx:1, width: 135}}  slotProps={{ textField: { variant: 'standard', } }} onChange={(date) => dateChangeHandler(date, "end")} minDate={interval.start}/>
						</Typography>
						{/* <Link component="button" color="inherit" onClick={onDateChange}>Change dates</Link> */}
					</div>

					<div className="headerRight">
						{hasUnresolved && (
							<ToggleButtonGroup
							color="primary"
							value={show}
							exclusive
							onChange={onShowChange}
							aria-label="Platform"
							>
								<ToggleButton value="resolved">Resolved</ToggleButton>
								<ToggleButton value="unresolved">Unresolved</ToggleButton>
							</ToggleButtonGroup>
						)}
					</div>
				</div>

				<div className="content">
					<MaterialReactTable
						id="table"
						className="table"
						sx={{
							width: "100%",
						}}
						displayColumnDefOptions={{
							"mrt-row-actions": {
								muiTableHeadCellProps: {
									align: "center",
								},
								size: 120,
							},
						}}
						columns={columns}
						data={data}
						enableGlobalFilter={false} //turn off a feature
						initialState={{
							columnVisibility: { id: false },
						}}
						enablePagination={true}
						// enableRowVirtualization
						// enableEditing
						// editingMode="cell"
						// renderRowActions={({ row, table }) => (
						// 	<Box sx={{ display: "flex", gap: "1rem" }}>
						// 		<Tooltip arrow placement="left" title="Cell">
						// 			<IconButton onClick={() => table.setEditingRow(row)}>
						// 				<Cell />
						// 			</IconButton>
						// 		</Tooltip>
						// 		<Tooltip arrow placement="right" title="Delete">
						// 			<IconButton
						// 				color="error"
						// 				onClick={() => handleDeleteRow(row)}
						// 			>
						// 				<Delete />
						// 			</IconButton>
						// 		</Tooltip>
						// 	</Box>
						// )}
						renderTopToolbarCustomActions={({ table }) => (
							<Box
								sx={{
									display: "flex",
									gap: "1rem",
									p: "0.5rem",
									flexWrap: "wrap",
								}}
							>
								<Button
									id="newEvent"
									color="primary"
									onClick={() => setCreateModalOpen(true)}
									variant="outlined"
									startIcon={<AddIcon />}
								>
									Event
								</Button>
								<Button
									id="newVenu"
									color="primary"
									onClick={() => setCreateVenuModalOpen(true)}
									variant="outlined"
									startIcon={<AddIcon />}
								>
									Venue
								</Button>
								{/* <Button
									color="primary"
									//export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
									onClick={handleExportData}
									startIcon={<FileDownloadIcon />}
									variant="contained"
								>
									Export All Data
								</Button> */}
							</Box>
						)}
						renderDetailPanel={({ row }) => (
							<Box
								sx={{
									display: "flex",
								}}
							>
								<Box>
									{row.original?.eventDetails?.summary && (
										<Typography>
											Summary: {row.original.eventDetails.summary}
										</Typography>
									)}
									{row.original?.eventDetails?.location && (
										<Typography>
											Location: {row.original.eventDetails.location}
										</Typography>
									)}
									{row.original?.eventDetails?.description && (
										<Typography>
											Description: {row.original.eventDetails.description}
										</Typography>
									)}
								</Box>
							</Box>
						)}
					/>
				</div>

				<div className="footer">
					<Button variant="contained" color="primary" disabled={hasUnresolved} onClick={onPublish}>Review</Button>
				</div>
			</>}

			{createModalOpen && (
				<AddEntry
					id="add-entry"
					columns={columns}
					open={createModalOpen}
					onSubmit={onCreateEvent}
					onClose={() => setCreateModalOpen(false)}
					bands={bands}
					venues={venues}
					onAddBand={onAddBand}
					onAddVenue={onAddVenue}
					// onSubmit={handleCreateNewRow}
				/>
			)}

			{createVenuModalOpen && <AddItem
        columns={venueColumns}
        // initialValues={dialogValue}
        open={createVenuModalOpen}
        titleText="Add Venue"
        buttonText="Save"
        onClose={() => setCreateVenuModalOpen(false)}
        onSubmit={onAddVenue}
      />}
		</div>
	);
};

export default ReviewEntries;
