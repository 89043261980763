import React, { useEffect, useReducer } from "react";
import AppContext from "./AppContext";

import TopNav from "./components/topNav";
import ReviewEntries from "./features/schedule/components/ReviewEntries";
import PublishEvents from "./features/schedule/components/PublishEvents";
import { useFetchBandsTablesQuery, useAddBandMutation } from "./features/bands/bandsSlice";
import { useFetchVenuesTablesQuery, useAddVenueMutation } from "./features/venues/venuesSlice";
import { useFetchEventsQuery, resolveEvents } from './features/calendarEvents/calendarEventsSlice.js';

import { Interval,DateTime } from 'luxon';

import "./App.scss";
import CssBaseline from "@material-ui/core/CssBaseline";

const initialState = {
	interval: Interval.fromDateTimes(DateTime.now().startOf('week').startOf('day'), DateTime.now().startOf('week').plus({days: 6})).toISO(),
	show: "unresolved",
	events: undefined,
	unresolved: undefined,
	publish: false,
};

const reducer = (state, action) => {
	console.log(action.type);
	switch (action.type) {
		case "publish":
			return { ...state, publish: true };
		case "updateInterval":
			return { ...state, interval: action.payload.toISO() };
		case "resolutionCompleted": {
			const resolved = [];
			const unresolved = [];
			action.payload.forEach((item) => {
				const { band, venue } = item;
				band?.id && venue?.id ? resolved.push(item) : unresolved.push(item);
			});
			return { ...state, events: resolved, unresolved };
		}
		case "updateEvent": {
			const { value, index, prop } = action.payload;
			const updated = [...state.events];
			updated[index][prop] = value;
			return { ...state, events: updated };
		}
		case "newEvent": {
			const updated = [...state.events, action.payload];
			return { ...state, events: updated };
		}
		case "updateUnresolved": {
			const { value, index, prop } = action.payload;
			const unresolved = [...state.unresolved];
			unresolved[index][prop] = value;
			if (unresolved[index]?.band?.id && unresolved[index]?.venue?.id) {
				// add to resolved
				const events = [...state.events, unresolved[index]];
				// remove from unresolved
				unresolved.splice(index, 1);
				return { ...state, events: events, unresolved: unresolved };
			} else {
				return { ...state, unresolved: unresolved };
			}
		}
		case "updateShowValue": {
			return { ...state, show: action.payload };
		}
		case "clearInterval": {
			return { ...state, interval: undefined }
		}
		case "reset":
			return initialState;
		default:
			return state;
	}
};

const App = () => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [ addVenue ] = useAddVenueMutation();
	const [ addBand ] = useAddBandMutation();

	const { data: fetchedBands, isSuccess: bandsIsSuccess} = useFetchBandsTablesQuery();
	const { data: fetchedVenues, isSuccess: venuesIsSuccess } = useFetchVenuesTablesQuery();
	const { data: fetchedEvents, isSuccess: eventsIsSuccess } = useFetchEventsQuery(state.interval);

	useEffect(()=>{
		if (bandsIsSuccess && venuesIsSuccess && eventsIsSuccess) {
			const resolvedEvents = resolveEvents(fetchedEvents, fetchedBands, fetchedVenues);
			dispatch({type: 'resolutionCompleted', payload: resolvedEvents});
		}
	}, [bandsIsSuccess, eventsIsSuccess, fetchedBands, fetchedEvents, fetchedVenues, venuesIsSuccess])

	const handleEventChange = (value, cell) => {
		const type = state.show === "resolved" ? "updateEvent" : "updateUnresolved";
		const index = cell.row.index;
		const prop = cell.column.id;

		dispatch({ type: type, payload: { value, index, prop } });
		setTimeout(() => {
			document.getElementById('newEvent').focus();							
		}, 300);
		console.log(value);
	}

	const handlePublishEvent = () => dispatch({type: 'publish'});
	const handleDateChange = (interval) => dispatch({type: 'updateInterval', payload: interval});
	const handleShowChange = (event) => dispatch({ type: "updateShowValue", payload: event.target.value });
	const handleAddVenue = (value) => addVenue(value);
	const handleAddBand = (value) => addBand(value)
	const handleCreateEvent = (event) => dispatch({ type: "newEvent", payload: event });
	const handleDeleteEvent = () => {
		debugger;
	// 	if (
	// 		!window.confirm(
	// 			`Are you sure you want to delete ${row.getValue(
	// 				"band"
	// 			)} at ${row.getValue("venue")} on ${row.getValue("startDate")}?`
	// 		)
	// 	) {
	// 		return;
	// 	}
	// }, []);
	}

	return (
		<AppContext.Provider value={[state, dispatch]}>
			<CssBaseline>
				<TopNav />
				<div className="mainContent">

					{state.publish && <PublishEvents events={state.events}/>}

					{state.interval && state.events && state.unresolved && !state.publish &&
						<ReviewEntries
							bands={fetchedBands}
							onAddBand={handleAddBand}
							venues={fetchedVenues}
							onAddVenue={handleAddVenue}
							events={state.events}
							onEventChange={handleEventChange}
							unresolved={state.unresolved}
							show={state.show}
							onShowChange={handleShowChange}
							interval={Interval.fromISO(state.interval)}
							onDateChange={handleDateChange}
							onDeleteEvent={handleDeleteEvent}
							onCreateEvent={handleCreateEvent}
							onPublish={handlePublishEvent}
						/>}
				</div>
			</CssBaseline>
		</AppContext.Provider>
	);
};

export default App;
