
// const columns = useMemo (
//   () => [
//     {
//       accessorKey: 'id',
//       header: 'ID',
//       enableColumnOrdering: false,
//       enableEditing: false, //disable editing on this column
//       enableSorting: false,
//       size: 0,
//     },
//     {
//       accessorKey: 'startDate',
//       header: 'date',
//       // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
//       //   ...getCommonEditTextFieldProps(cell),
//       //   type: 'email',
//       // }),
//     },
//     {
//       accessorKey: 'startTime',
//       header: 'Start',
//       size: 80,
//       // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
//       //   ...getCommonEditTextFieldProps(cell),
//       //   type: 'number',
//       // }),
//     },
//     {
//       accessorKey: 'endTime',
//       header: 'end',
//       size: 80,
//       // muiTableBodyCellEditTextFieldProps: {
//       //   select: true, //change to select for a dropdown
//       //   children: states.map((state) => (
//       //     <MenuItem key={state} value={state}>
//       //       {state}
//       //     </MenuItem>
//       //   )),
//       // },
//     },
//     {
//       accessorKey: 'band',
//       header: 'Band',
//       size: 140,
//       // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
//       //   ...getCommonEditTextFieldProps(cell),
//       // }),
//     },
//     {
//       accessorKey: 'venue',
//       header: 'Venue',
//       size: 140,
//       // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
//       //   ...getCommonEditTextFieldProps(cell),
//       // }),
//     },
//   ],
//   [/*getCommonEditTextFieldProps*/],
// );

const bandColumns = [
  {
    header: "Name",
    accessorKey: "Name",
  },
  {
    header: "Genres",
    accessorKey: "genres"
  }
];

const venueColumns = [
	{
		header: "Venue",
		accessorKey: "Venue",
	},
	{
		header: "Street",
		accessorKey: "Street",
    size: 250,
	},
	{
		header: "City",
		accessorKey: "City",
    size: 100,
	},
	{
		header: "State",
		accessorKey: "State",
    size: 50,
	},
	{
		header: "Zip",
		accessorKey: "Zip",
    size: 50,
	},
];

export {
  bandColumns,
  venueColumns
}