import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CLIENT_ID } from './features/api/googleConstants';
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { store } from "./app/store";
import { Provider } from "react-redux";
const queryClient = new QueryClient();

ReactDOM.render(
<Router>
  <GoogleOAuthProvider clientId={CLIENT_ID}>
    <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <App />
        </LocalizationProvider>
      </Provider>
      </QueryClientProvider>
    </React.StrictMode>
  </GoogleOAuthProvider>
</Router>
, document.getElementById('root'));