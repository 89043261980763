import React from 'react'
import { NavLink } from 'react-router-dom'
// import Auth from '../auth/Auth'
import './styles.scss';

const TopNav = () => {
  return (
    <>
    <div className="topNav">
        {/* <nav>
          <NavLink to="/">Home</NavLink>
            <>
              <NavLink to="/bands">Bands</NavLink>
              <NavLink to="/venues">Venues</NavLink>
              <NavLink to="/schedule">Schedule</NavLink>
            </>
        </nav> */}
        {/* <Auth/> */}
    </div>
    </>
  )
}

export default TopNav