import { nanoid } from "@reduxjs/toolkit";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

const filter = createFilterOptions();

export default function BandsSelect({bands, selected, onChange, onAddBand}) {

	return (
		<Autocomplete
			value={selected}
			options={bands}
			onChange={(event, newValue) => {
				if (typeof newValue === "string") {
					onChange({ Name: newValue });
				} else if (newValue && newValue.inputValue) {
					// Create a new value from the user input
					onAddBand({ Name: newValue.inputValue });
					onChange({ Name: newValue.inputValue });
				} else {
					onChange(newValue);
				}
			}}
			filterOptions={(options, params) => {
				const filtered = filter(options, params);
				const { inputValue } = params;

				// Suggest the creation of a new value
				const isExisting = options.some((option) => inputValue === option.Name);
				if (inputValue !== "" && !isExisting) {
					filtered.push({
						id: nanoid(),
						inputValue,
						Name: `Add "${inputValue}"`,
					});
				}

				return filtered;
			}}
			selectOnFocus
			clearOnBlur
			handleHomeEndKeys
			id="bands-select"
			getOptionLabel={(option) => {
				// Value selected with enter, right from the input
				if (typeof option === "string") {
					return option;
				}
				// Add "xxx" option created dynamically
				if (option.inputValue) {
					return option.inputValue;
				}
				// Regular option
				return option?.Name?.toString() || "Unmatched";
			}}
			renderOption={(props, option) => (
				<li {...props} key={option.id}>
					{option.Name}
				</li>
			)}
			freeSolo
			renderInput={(params) => (
				<TextField {...params} size="small" variant="filled" label="Select a band"/>
			)}
			
		/>
	);
}
