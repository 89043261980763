import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { gapi } from "gapi-script";
import {
	API_KEY,
	CAL_DISCOVERY_DOC,
	CAL_ID,
	CLIENT_ID,
	SCOPES,
} from "../api/googleConstants";
import _ from "lodash";
import { DateTime, Interval } from "luxon";

const matchVenue = (venues, eventData) => {
	const normalize = (string) =>
		("" + string).toLowerCase().replaceAll("&", "and").replaceAll(",", "");
	const normalizedEventData = normalize(eventData);

	const found = venues.find((venue) => {
		const normalizedVenueName = normalize(venue.Venue);
		// const normalizedVenueAddress = normalize(venue.Street);

		const item =
			normalizedEventData.includes(normalizedVenueName) 
			// normalizedEventData.includes(normalizedVenueAddress);
		return item;
	});
	return found;
};

const matchBand = (collection, eventData) => {
	const normalize = (string) => {
		try {
			const value =
				("" + string)
					.trim()
					.toLowerCase()
					.replaceAll("&", "and")
					.replaceAll(",", "") || "No Match";
			return value;
		} catch (err) {
			console.log(err);
		}
	};

	if (!eventData) {
		debugger;
	}
	const normalizedEventData = normalize(eventData);

	const found = collection.find((item) => {
		if (!item.Name) return false;
		const normalizedName = normalize(item.Name);
		const found = normalizedEventData.includes(normalizedName);
		return found;
	});
	return found;
};

export const resolveEvents = (events, bands, venues) => {
	const data = [];

	// Loop through all calendar events found and write them out starting on calulated ROW 2 (i+2)
	for (let i = 0; i < events.length; i++) {
		const event = events[i];
		if (event.status !== "confirmed") {
			break;
		}

		const startTime = DateTime.fromISO(event.start.dateTime);
		const endTime = DateTime.fromISO(event.end.dateTime);
		const eventData = `${event.summary} ${event.location} ${event.description}`;

		const foundBand = matchBand(bands, eventData);
		const foundVenue = matchVenue(venues, eventData);

		const details = {
			startTime,
			endTime,
			band: foundBand,
			venue: foundVenue,
			eventDetails: {
				summary: event.summary,
				location: event.location,
				description: event.description,
			},
		};

		data.push(details);
	}

	const ordered = _.orderBy(
		data,
		["startDate", "startTime", "endTime"],
		["asc", "asc", "asc"]
	);
	return ordered;
};

const fetchCalendarEvents = async (interval) => {
	let promiseResolve, promiseReject;
	
	let promise = new Promise((resolve, reject) => {
		promiseResolve = resolve;
		promiseReject = reject;
	});
	
	gapi.load("client:auth2", async() => {
		await gapi.client
		.init({
			apiKey: API_KEY,
			clientID: CLIENT_ID,
			scopes: SCOPES.join(" "),
			discoveryDocs: [CAL_DISCOVERY_DOC],
		})
			if (interval) {
				const { start, end } = Interval.fromISO(interval);
				// console.log(`fetching events from ${start} tp ${end}`);

				const response = await gapi.client.calendar.events
					.list({
						calendarId: CAL_ID,
						timeMin: start.toISO(),
						timeMax: end.toISO(),
						timeZone: "America/New_York",
						singleEvents: true,
					});

					if (response.status === 200) {
						promiseResolve(response.result.items);
					} else {
						promiseReject(response.statusText);
					}
			}
	});
	return promise;
};

export const eventsApi = createApi({
	baseQuery: fakeBaseQuery(),
	tagTypes: ["Events"],
	reducerPath: "events",
	endpoints: (builder) => ({
		// use RTK Query to invoke the google calendar API to fetch events
		fetchEvents: builder.query({
			async queryFn(interval) {
				try {
					const events = await fetchCalendarEvents(interval);
					return { data: events };
				} catch (error) {
					console.error(error.message);
					return { error: error.message };
				}
			},
			providesTags: ["Bands"],
		}),
		// addBand: builder.mutation({
		//   async queryFn(bandInfo) {
		//     try {
		//       const ref = collection(firestore, 'bands');
		//       const data = { ...bandInfo, isDeleted: 'FALSE', lastModified: new Date().toString()};
		//       await addDoc(ref, data);
		//       return { data: null };
		//     } catch (error) {
		//       console.error(error.message);
		//       return { error: error.message };
		//     }
		//   },
		//   invalidatesTags: ['Bands'],
		// }),
	}),
});

// RTKQuery creates hooks for mutations and queries
export const { useFetchEventsQuery } = eventsApi;
