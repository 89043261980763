import {useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import BandsSelect from '../../bands/BandsSelect.js'
import VenuesSelect from '../../venues/VenuesSelect.js'

const AddEntry = ({open, columns, bands, venues, onAddBand, onAddVenue, onClose, onSubmit}) => {
  const [values, setValues] = useState([]);

  const isValid = () => values.band && values.venue && values.startDate && values.startTime && values.endTime;

  const onValueChange = (key, value) => {
    setValues({...values, [key]: value})
  }

  const handleSubmit = () => {
    debugger;
    if (isValid()) {
      onSubmit(values);
    }
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">New Event</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
            }}
          >
            {columns.map((column) => {
              if (column.type === 'date') {
                return <DatePicker key={column.id} onChange={(newValue) => onValueChange(column.id, newValue)}/>
              }
              if (column.type === 'time') {
                return <TimePicker  key={column.id} onChange={(newValue) => onValueChange(column.id, newValue)}/>
              }
              if (column.type === 'band') {
                return <BandsSelect 
                  key={column.id} 
                  bands={bands}
                  onAddBand={onAddBand}
                  onChange={(newValue) => onValueChange(column.id, newValue)}
                // onChange={(value) => onEventChange(value, cell)}
                
                
                />
              }
              if (column.type === 'venue') {
                return <VenuesSelect 
                  key={column.id}
                  venues={venues}
                  onAddBand={onAddVenue}
                  onChange={(newValue) => onValueChange(column.id, newValue)}/>
              }
              else {
                return <></>
              }
            }
            )}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit} variant="contained" disabled={!isValid}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEntry;