import  { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAuth, connectAuthEmulator } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDsfbjVxLDWmjUxZ-ZRvZ2nI8Wdeskwhr0",
  authDomain: "lovemusic-376921.firebaseapp.com",
  databaseURL: "https://lovemusic-376921-default-rtdb.firebaseio.com",
  projectId: "lovemusic-376921",
  storageBucket: "lovemusic-376921.appspot.com",
  messagingSenderId: "159649023887",
  appId: "1:159649023887:web:11d3c30c751743a3f0adfa",
  measurementId: "G-J305QKWRQ6"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);

if (window.location.hostname === 'localhost') {
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  connectAuthEmulator(auth, 'http://localhost:9099/', { disableWarnings: true });
}


export default firestore;