
import {bandsApi} from '../features/bands/bandsSlice';
import {venuesApi}  from '../features/venues/venuesSlice';
import {eventsApi} from '../features/calendarEvents/calendarEventsSlice';

import { configureStore } from '@reduxjs/toolkit';

export const setupStore = () =>
  configureStore({
    reducer: {
      [bandsApi.reducerPath]: bandsApi.reducer,
      [venuesApi.reducerPath]: venuesApi.reducer,
      [eventsApi.reducerPath]: eventsApi.reducer,
    },

    // uses RTK Query as middlewares
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware().concat(bandsApi.middleware, venuesApi.middleware, eventsApi.middleware);
    },
  });

export const store = setupStore();