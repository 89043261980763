import {useState} from 'react'
import {venueColumns} from '../../constants/table';
import AddItem from '../../components/AddItem';

import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

export default function VenuesSelect({venues, selected, onChange, onAddVenue}) {
  const [dialogValue, setDialogValue] = useState({});
  const [open, toggleOpen] = useState(false);

  const handleClose = () => {
    setDialogValue({});
    toggleOpen(false);
  };

  // const isValid = (val) => val.name.length > 0 && val.street.length > 0 && val.city.length > 0 && val.state.length > 0 && val.zip.length > 0;

  return (
    <>
      <Autocomplete
        id="venues-select"
        options={venues}
        value={selected}
        onChange={(event, newValue) => {
          const venue = (typeof newValue === 'string') ? newValue : (newValue?.inputValue) ? newValue.inputValue : null;
          if (venue) {
            // timeout to avoid instant validation of the dialog's form.
            setDialogValue({Venue: venue});
            setTimeout(() => { toggleOpen(true) }, 500);
          } else {
            onChange(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              Venue: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        getOptionLabel={(option) => {
          const label = (typeof option === 'string') ? option : (option.inputValue) ? option.inputValue : option.Venue;
          return label;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props} key={option.id}>{option.Venue}</li>}
        freeSolo
        renderInput={(params) => <TextField {...params} size="small" variant="filled" label="Select a venue" />}
      />

      {open && <AddItem
        columns={venueColumns}
        initialValues={dialogValue}
        open={open}
        titleText="Add Venue"
        buttonText="Save"
        onClose={handleClose}
        onSubmit={onAddVenue}
      />}
    </>
  );
}
