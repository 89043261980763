const PROD_CAL_ID = "localmusic@bandsandclubsofthetriangle.com";
const PROD_DESTINATION_FOLDER_ID ="15qFrrhYnmQoUNJD4ko5UAS4DnmEhbx0-";
const PROD_BANDS_SHEET_ID = "1wDK-hwMCmq7lEn_Z3uhB78c5e3VJOdVFmcObBgAQ7QI"
const PROD_VENUES_SHEET_ID = "1HKwiJF4qIWIW-dVRSdTGFr-YX2MHs5SM7UnAVhaDeAU"
const PROD_DOC_TEMPLATE_FOLDER = "1vOrzrkY0vddNHSEWBEZjoZrR2gKIfUN7";
const PROD_DOC_TEMPLATE_ID = "1Y8Yvpor5Vcio5F0QHXyo0qeuvhCOkTYg5sLGUaHvNz4";

const DEV_CAL_ID = "c_783579fe0d0b7f47fe9da4821a53713f11a9d6458feff5efc757b8a6ff064e7e@group.calendar.google.com";
const DEV_DESTINATION_FOLDER_ID ="1ZT1Gz2yOjNFf12YzOD0Qnz3mai6fCcGK";
const DEV_BANDS_SHEET_ID="1x50tQsj8vUUln2SlKLBvWS3D13T1x3c-IikEyf0pS-w";
const DEV_VENUES_SHEET_ID="1vQgd0cJUKVyS-pZ_lF22qfMgcO-CrklL3YM1FEPmKMI";
const DEV_DOC_TEMPLATE_FOLDER = "1dBruokg_DY0-nZihVw5B8AjizNUvexI0";
const DEV_DOC_TEMPLATE_ID = "1p4U2b_F1BWICI46WRlY1Y4WoIuh8yu7YmvCWUVSa_UU";

// File Location contstants
const env = process.env.NODE_ENV;

export const CAL_ID = (env === "prod") ? PROD_CAL_ID : PROD_CAL_ID;
export const BANDS_SHEET_ID = (env === "prod") ? PROD_BANDS_SHEET_ID : DEV_BANDS_SHEET_ID;
export const VENUES_SHEET_ID = (env === "prod") ? PROD_VENUES_SHEET_ID : DEV_VENUES_SHEET_ID;
export const DOC_TEMPLATE_FOLDER = (env === "prod") ? PROD_DOC_TEMPLATE_FOLDER : DEV_DOC_TEMPLATE_FOLDER;
export const DOC_TEMPLATE_ID = (env === "prod") ? PROD_DOC_TEMPLATE_ID : DEV_DOC_TEMPLATE_ID;
export const DESTINATION_FOLDER_ID = (env === "prod") ? PROD_DESTINATION_FOLDER_ID : DEV_DESTINATION_FOLDER_ID;
export const SCRIPTS_FOLDER_ID = "1Yjo0J8c0GNsPtGgKzgMgyxk17LYwKIOi";
export const DAYS_OF_WEEK = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const MONTHS_OF_YEAR = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const SHEET_TABLE_HEADERS = ["Day","Start date","Start time","End time","Band","Venue","City","Location","Description"];


// API Access constants
export const CLIENT_ID = "159649023887-599h0nsag6q1mr3v8oki8oo8dbkgmk2m.apps.googleusercontent.com";
export const API_KEY = "AIzaSyBfc66mg6P60VR0GSvYZ33m3a3Oab-DcAc"
export const SCOPES = [ 'https://www.googleapis.com/auth/drive', 'https://www.googleapis.com/auth/calendar' , 'profile'];
export const SHEETS_DISCOVERY_DOC = "https://sheets.googleapis.com/$discovery/rest?version=v4"
export const BANDS_SHEET_RANGE = 'A1:A';
export const VENUES_SHEET_RANGE = 'A1:E';

export const CAL_DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';