import MaterialReactTable from "material-react-table";
import { Typography } from "@material-ui/core";
import { DateTime } from "luxon";

import "../styles.scss";

const PublishEvents = ({events}) => {

	const columns = [
		{
			id: "startTime",
			accessorFn: (row) => `${row.startTime.toLocaleString(DateTime.TIME_SIMPLE)} - ${row.endTime.toLocaleString(DateTime.TIME_SIMPLE)}`,
			header: "Time",
			size: 1,
			type: "time",
		},
		{
			id: "band",
			accessorFn: (row) => row.band.Name,
			header: "Band",
			size: 1,
			type: "band",
		},
		{
			id: "venue",
			accessorFn: (row) => row.venue.Venue,
			header: "Venue",
			size: 1,
			type: "venue",
		},
		{
			id: "address",
			accessorFn: (row) => `${row.venue.Street}, ${row.venue.City},  ${row.venue.State},  ${row.venue.Zip} `,
			header: "Address",
			// type: "venue",
		},
	];

	const groupedArray = events.reduce((acc, curr) => {
		const key = curr.startTime.startOf('day').ts;
		acc[key] = acc[key] || [];
		acc[key].push(curr);
		return acc;
	}, {});

	return (
		<div className="page">
			<div className="publish">
				{Object.entries(groupedArray).map(([key,value]) => (
					<div key={key}>
						<Typography color="primary" variant="h6">
							{DateTime.fromMillis(parseInt(key)).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
						</Typography>
						<MaterialReactTable
							id="table"
							className="table"
							sx={{width: "fixed"}}
							columns={columns}
							data={value}
							initialState={{ density: 'compact' }}
							enableTopToolbar={false}
							enableBottomToolbar={false}
							enablePagination={false}
						/>
					</div>
				))}

			</div>
		</div>
	);
};

export default PublishEvents;
