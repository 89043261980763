import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import firestore from '../api/firestore';

// RTKQuery wraps Firestore query
export const bandsApi = createApi({
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Bands'],
  reducerPath: 'bands',
  endpoints: (builder) => ({
    fetchBandsTables: builder.query({
      async queryFn() {
        try {
          const ref = collection(firestore, 'bands');
          const querySnapshot = await getDocs(ref);
          let table = [];
          querySnapshot?.forEach((doc) => {
            doc.data().isDeleted === 'FALSE' && table.push({ id: doc.id, ...doc.data() });
          });
          table.sort((a,b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0))
          return { data: table };
        } catch (error) {
          console.error(error.message);
          return { error: error.message };
        }
      },
      providesTags: ['Bands'],
    }),
    addBand: builder.mutation({
      async queryFn(bandInfo) {
        try {
          const ref = collection(firestore, 'bands');
          const data = { ...bandInfo, isDeleted: 'FALSE', lastModified: new Date().toString()};
          await addDoc(ref, data);
          return { data: null };
        } catch (error) {
          console.error(error.message);
          return { error: error.message };
        }
      },
      invalidatesTags: ['Bands'],
    }),
  }),
});

// RTKQuery creates hooks for mutations and queries 
export const { useFetchBandsTablesQuery, useAddBandMutation } = bandsApi;