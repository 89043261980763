import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import firestore from '../api/firestore';

export const venuesApi = createApi({
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Venues'],
  reducerPath: 'venues',
  endpoints: (builder) => ({
    fetchVenuesTables: builder.query({
      async queryFn() {
        try {
          const ref = collection(firestore, 'venues');
          const querySnapshot = await getDocs(ref);
          let table = [];
          querySnapshot?.forEach((doc) => {
            doc.data().isDeleted === 'FALSE' && table.push({ id: doc.id, ...doc.data() });
          });
          table.sort((a,b) => (a.Venue > b.Venue) ? 1 : ((b.Venue > a.Venue) ? -1 : 0))
          return { data: table };
        } catch (error) {
          console.error(error.message);
          return { error: error.message };
        }
      },
      providesTags: ['Venues'],
    }),
    addVenue: builder.mutation({
      async queryFn(venueInfo) {
        try {
          const ref = collection(firestore, 'venues');
          const data = { ...venueInfo, isDeleted: 'FALSE', lastModified: new Date().toString()};
          await addDoc(ref, data);
          return { data: null };
        } catch (error) {
          console.error(error.message);
          return { error: error.message };
        }
      },
      invalidatesTags: ['Venues'],
    }),
  }),
});

export const { useFetchVenuesTablesQuery, useAddVenueMutation } = venuesApi;