import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";

const AddItem = ({open, columns, initialValues, titleText, buttonText, onClose, onSubmit}) => {
  const [values, setValues] = useState(initialValues);

  const handleSubmit = () => {
    onSubmit(values);
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">{titleText}</DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            width: "100%",
            minWidth: { xs: "300px", sm: "360px", md: "400px" },
            gap: "1.5rem",
          }}
        >
          {columns.map((column) => (
            <TextField
              key={column.accessorKey}
              label={column.header}
              name={column.accessorKey}
              defaultValue={values?.[column.accessorKey]}
              onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
            />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit} variant="contained">
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddItem;